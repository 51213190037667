import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='Über mich' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Über mich'
            subheader='Mein Name ist Ina Hild, ich bin verheiratet und habe drei Kinder.
            Seit 2009 bin ich approbierte Psychotherapeutin.
            Nach dem Psychologiestudium in Kiel habe ich die Therapeutenweiterbildung 
            in Köln bei der Akademie für Verhaltenstherapie absolviert. 
            Die praktische Tätigkeit in diesem Rahmen fand größtenteils in der Uniklinik Köln statt.
            Von 2011 bis Mitte 2021 konnte ich bei meiner Arbeit in der Psychiatrischen Institutsambulanz 
            des Kieler Fensters weitere vielseitige psychotherapeutische und allgemeinpsychiatrische Erfahrungen sammeln.
            Seit August 2021 bin ich in eigener Praxis tätig. 
            Da ich sehr gerne mit Menschen mit autistischer Wahrnehmungsverarbeitung arbeite, 
            habe ich mich zunehmend in diese Richtung spezialisiert und fortgebildet.
            Ich behandle jedoch weiterhin auch gerne Menschen mit anderen Diagnosen, beziehungsweise 
            Problematiken.
            Bitte haben Sie Verständnis dafür, dass ich nicht in allen Bereichen 
            gleichermaßen geeignet sein kann. In diesen Fällen bemühe ich mich, Ihnen eine passende
            Empfehlung auszusprechen.'
          />
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query MyAboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
